import React from 'react';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import './index.css'

export default function MainDescription() {
    const matches = useMediaQuery('(min-width: 768px)');

    return (
        <div className='home-details no-web-display' style={matches ? { padding: '20px' }:{}}>
            <h1 className='heading'><span className='site-title'>Sports<span className='feed'>Feed</span>24</span> – Best sports live streaming platform</h1>
            <p className='card-description'>
                Welcome to the best sport streaming platform on internet. Sportsfeed 24 is the ultimate 
                platform to get high quality,free live sports streams soccer streams. 
                If you are looking for the best, safest and hustle free live soccer streaming experience then look no further, 
                you have already arrived at your destination. Our website provides the best streaming experience covering soccer streams and more. 
            </p>
            <p className='card-description'>
                On our platform you can stream various sports. 
                Sportsfeed 24 is a website where you can watch popular sports like Premier League, 
                LaLiga, Serie A, Ligue 1, Saudi Pro League, NBA, NHL, NFL, MLB, UFC, Boxing, and Formula 1 for free. 
                It is a site for live sports streaming that is widely used around the world.
            </p>
            <div>
                <ul className='heading-list'>
                    <li>
                        <h2>
                            <span className='collection'>Premier League Live Streams</span>
                        </h2>
                        <p className='card-description'>
                            Premier Leagues live streaming is available on sportsfeed 24 for free. We cover all Premier League matches for the ongoing season.
                            Sportsfeed 24 is host to live streaming of Premier League matches. You can watch all the Premier League matches for freeon this platform.
                            To Watch Live Premier leave matches, refer to this page. All the Premier League matches will be displayed with their timing on this page.
                            This platform offers multiple players to watch live soccer matches and it covers all the Premier League matches.
                        </p>  
                    </li>
                    <li>
                        <h2>
                            <span className='collection'><a href='https://www.sportsfeed24.com/leagues/standings'>Premier League Table and Standings</a></span>
                        </h2>
                        <p className='card-description'>
                            Premier League table shows the latest points and standings of all 20 teams of Premier League. You can refer to this link 
                            to find <a href='https://www.sportsfeed24.com/leagues/standings'>Premier League Table</a>.
                            In Premier League standings, you can also find the point tables of other popular soccer leagues as well.
                        </p>
                    </li>
                    <li>
                        <h2>
                            <span className='collection'><a href='https://www.sportsfeed24.com/fixtures/league/Premier League'>Premier League Fixtures</a></span>
                        </h2>
                        <p className='card-description'>
                            The most famous football league is on its way towards final matches of the season. Each team now just need to play less thank 10 more games.
                            You can find Premier League fixture on tsportsfeed 24 and schedule of matches of your favorite team. 
                            Go to <a href='https://www.sportsfeed24.com/fixtures/league/Premier League'>Premier League Fixture</a> to find the upcoming schedule of all of your favorite teams. Premier league matches, standings and schedule 
                            can be found on sportsfeed 24.
                            You can also find <a href='https://www.sportsfeed24.com/fixtures/league/UEFA Champions League'>UEFA Champions League fixtures</a> and all the fitures of specific team and popular leagues  
                            <a href='https://www.sportsfeed24.com/fixtures/league/Premier League'> HERE</a>.
                        </p>
                    </li>
                    <li>
                        <h2>
                            <span className='collection'>Soccer Streams</span>
                        </h2>
                        <p className='card-description'>
                        Experience the thrill of live soccer like never before with Sportsfeed 24’s HD quality soccer streams! 
                        Whether you’re at home or on the go, our fast and reliable soccer stream ensures you don’t miss a moment of the action. 
                        Watch live Premier League, LaLiga, Serie A, Ligue 1, and UEFA Champions League matches from anywhere in the world. 
                        Enjoy seamless soccer streams that bring the excitement of every game straight to your screen. 
                        For the best live soccer stream experience, choose Sportsfeed 24 and never miss a goal!
                        </p>
                    </li>
                    <li>
                        <h2>
                            <span className='collection' style={{color: 'rgb(98 112 245)'}}>Free24 Online Stream</span>
                        </h2>
                        <p className='card-description'>
                        If you are looking for best free online HD quality streams of your favorite sports, the look no further. 
                        You have found the best free website stream 24 streams to enjoy all free online HD streams. 
                        The site is designed for the best <span style={{color: 'rgb(98 112 245)'}}>free online 24 stream</span> experience of our users. 
                        On the home page, scroll down to find the match schedule, organized by time, date and competition. 
                        Select on the <span style={{color:'rgb(98 112 245)'}}>Soccer streams</span> option for instant access. 
                        You’ll be taken on the streaming page for the match. That’s it-sit back and enjoy!
                        </p>
                    </li>
                    <li>
                        <h2 className='nav-link'>
                            
                            <span className='collection'> <a href='https://www.sportsfeed24.com/fixtures/NFL/NFL'>NFL Live Streams</a></span>
                        </h2>
                        <p className='card-description'>
                            Watch Live NFL matches on sportsfeed24 official websites. We cover live streams of NFL streams.For our American football fans, we stream the NFL matches. 
                            Catch the matches of your favorite team on our platform.
                        </p>
                        <p className='card-description'>
                        <a href='https://www.sportsfeed24.com/fixtures/NFL/NFL'>NFL season 2024/2025</a> has just started. If you are excited to see your favourite team in action then you are on right platform. This NFL season, 
                        all games with be live streamed on our website with best quality .
                        </p>
                        <p className='card-description'>
                        The NFL season schedule is followed on our website for NFL free streams. We stream NFL games live . If you are bothered about high priced NFL tickets then you have our website to stree <a href='https://www.sportsfeed24.com/fixtures/NFL/NFL'>free NFL games</a> in best quality
                        </p>
                    </li>
                    <li >
                        <h2 className='nav-link'>
                            
                            <span className='collection'><a href='https://www.sportsfeed24.com/fixtures/cricket/Cricket'>Cricket Live Streams</a></span>
                        </h2>
                        <p className='card-description'>
                            To all cricket enthusiasts, you can experience all powerful sixes to the falls of wickets, 
                            everything can be live streamed on our live stream. 
                            Now you can watch all your favorite matches for free in HD quality. Watch live cricket streaming on sportsfeed 24.  
                        </p>
                    </li>
                    <li >
                        <h2 className='nav-link'>
                            
                            <span className='collection'><a href='https://www.sportsfeed24.com/fixtures/UFC/UFC'>UFC Live Streams</a></span>
                        </h2>
                        <p className='card-description'>
                            Are you a fan of UFC and looking for a website to live stream the matches without any hustle? 
                            Congratulations, you are on right platform. We live stream the best matches of UFC In the best quality. 
                            You don’t need to pay tons of fee to get access to these matches, 
                            our website provides the best free contend.
                            UFC live streaming is available on sportsfeed 24. Sportsfeed cover live matches of every event of UFC. 
                            You can watch free UFC live matches on sportsfeed24.
                        </p>
                    </li>
                    <li >
                        <h2 className='nav-link'>
                            
                            <span className='collection'><a href='https://www.sportsfeed24.com/fixtures/NBA/NBA'>NBA Live Streams</a></span>
                        </h2>
                        <p className='card-description'>
                            If you want to experience the excitement of watching basketball matches then look on further. 
                            You have already chosen the right platform. 
                            Stream all NBA matches and enjoy. To watch free NBA live streams for free and cover every 
                            match and enent of NBA, visit our official page of sportsfeed 24
                        </p>
                    </li>
                    <li >
                        <h2 className='nav-link'>
                            <span className='collection'>KEY SERVICES - SPORTS FIXTURES</span>
                        </h2>
                        <p className='card-description'>
                            ON OUR HOME PAGE WE PROVIDE TODAYS FOOTBALL FIXTURES AND RESULTS
                            WE WILL SHOW YOU FOOTBALL MATCHES TODAY FROM TOP LEAGUES ROUND THE WORLD WICH INCLUDES:
                            PREMIER LEAGUE FIXTURES
                            LA LIGA FIXTURES
                            SERIE A FIXTURES
                            BUNDESLIGA FIXTURES
                            SUPER LIGA FIXTURES
                            PRIMERIA LIGA
                            WE WILL BE ADDING MORE IN THE NEAR FUTURE
                            OUR SYSTEM IS AUTOMATED AND WILL KEEP YOU UPDATED ON DAILY FOOTBALL FIXTURES
                            AND DAILY FOOTBALL RESULTS ON YOUR FAVOURITE FOOTBALL LEAGUE

                        </p>    
                        <p className='nav-link'>
                            <span className='collection'>
                                How to watch live football for free?
                            </span>
                        </p>
                        <p className='card-description'>
                            Sportsfeed24 is host to live football and other sports streams. You can watch live matches of all the famous leagues, you favorite football team and 
                            experience the best free sports streaming service.
                            You can watch live football and sports matches on you phone, computer, tablets, without any ads and without paying for the service. The live streaming of the matches\
                            are free and provides a great experience for the users.
                            If you want to have a platform where you can find all the games and fixtures related to you favorite teams leagues and sports, the Sportsfeed24
                            is go to platform. 
                        </p>                      
                    </li>
                    <li >
                        <h2 className='nav-link'>
                            
                            <span className='collection'>Ad-Free Live Sports Streaming</span>
                        </h2>
                        <p className='card-description'>
                            Best thing about sportsfeed24 is that it provides ad fee live streams of your favorite football matches. You can browse through football matches and other sports and ramain
                            up to date with the fixtures and matches.
                            It is not a good experienc when you have to watch ads in the middle of you favorite sports matches. Sportsfeed24 understands Thisand 
                            therefore take care of user experience. It provide free live streaming of sports to its users without ads.
                        </p>     
                    </li>

                    <li >
                        <h2>
                            <span className='collection'>Why sportsfeed 24?</span>
                        </h2>
                        <div>
                        <p className='card-description'>
                            <strong>Sportsfeed 24 provides many numerous benefits for Fans.</strong>
                        </p>
                        <ul className='heading-list'>
                            <li>
                                <p className='card-description'>
                                    Smooth live streaming on all devices.
                                </p>
                            </li>
                            <li>
                                <p className='card-description'>
                                    High-definition graphics and immersive audio quality.
                                </p>
                            </li>
                            <li>
                                <p className='card-description'>
                                    Quick loading of live streams without buffering delays ensures an uninterrupted viewing experience.
                                </p>
                            </li>
                            <li>
                                <p className='card-description'>
                                    Allowing users to quickly access live stream without any technical complications.
                                </p>
                            </li>
                        </ul>
                        </div>     
                    </li>
                    
                    <li >
                        <p className='nav-link'>
                            
                            <span className='collection'>FAQs</span>
                        </p>
                        <p className='collection'>
                            Is it necessary to make an account to watch Sports streams?
                        </p>
                        <p className='card-description'>
                            Nope, you don’t have to. Just go to Sportsfeed 24 website and start watching any sports without making an account. Easy! 
                        </p>
                        <p className='collection'>
                            <strong>IS it safe to use Sportsfeed 24?</strong>
                        </p>
                        <p className='card-description'>
                            No pesky pop-up ads! Just open your browser, go to Sportsfeed 24, and watch any sport you want. It’s all safe for your device.
                        </p>
                        <p className='collection'>
                            <strong>Where to watch NFL final live for free?</strong>
                        </p>
                        <p className='card-description'>
                            You can watch  <a href='https://www.sportsfeed24.com/fixtures/NFL/NFL'>live NFL</a> on sportsfeed24 and streameast soccer. The NFL finalis to be played on 12 Feb, 2024. Sportsfeed24 will provide live streaming of NFL final.
                            Two best team, Kansas City Chiefs and San Francisco 49ers, will for the title and lagecy. Watch live match free and without any interuption only on your favorite streaming site sportsfeed24.     
                        </p>
                        <p className='collection'>
                            <strong>Where to find Update and live matches of <a href='https://www.sportsfeed24.com/fixtures/league/Premier League'>Premier League?</a></strong>
                        </p>
                        <p className='card-description'>
                            SportsFeed24 is the place for you to find out all about Premier League Table, Premier League Winner List, Premier League Standings, Premier League Results, Premier League Games, Premier League Stats, Premier League Players and Premier League Schedule. We offer the highest quality HD live streaming of all your favorite teams. 
                        </p>
                    </li>
                    <li>
                        <p>
                            
                            <span className='collection'>Conclusion</span>
                        </p>
                        <p className='card-description'>
                            In addition to these, our website always provide many more live streams of other sports for free in the best quality. 
                            We take pride on being one of the best streaming services of the world. HAPPY STEREAMING , ENJOY YOUR SPORTS . 
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    );
};