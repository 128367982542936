/* eslint-disable no-console */
/* eslint-disable camelcase */
// import { Row } from 'antd';

import { useMediaQuery } from '../../hooks/MediaQuery.hook';
// import { getIframe } from '../../apis/fixture.api';
import { useEffect, useState } from 'react';
import Loader from '../../components/common/Loader';
import { Button, Col, Row, Space } from 'antd';
// import AdSenseBanner from '../Ads';
// import { ADS } from '../../utils/constant';
import { styles } from '../../styles';
import { Link } from 'react-router-dom';
import { shareLink } from '../../utils/socialShare';
import { FacebookFilled, LinkedinFilled, MessageFilled, RedditOutlined, SkypeFilled, TwitterSquareFilled } from '@ant-design/icons';
import PlayerDropdown from './playerDropdown';

  export default  function  Player({ fixture }: any) {
    const matches = useMediaQuery('(min-width: 768px)');
    const [sourceLink, setSourceLink] = useState<any>('');
    const [loader, setLoader] = useState<boolean>(false);
    const [players, setPlayers] = useState<any[]>([]);
    const playerBorder =  matches ? styles.playerBorder: styles.playerBorderMobile
  
    const getFixtureFrame = async ()=> {
      setLoader(true);
      const website = 'freesport';
      const links = fixture?.game?.streamerLinks;
      const playersList = [];
      let streamSource = '';
      for (const item of links) {
        // if (item?.website?.trim() === 'Elite') {
        //   if (!streamSource) {
        //     streamSource = item?.websiteLink;
        //     playersList.unshift({websiteLink: item?.websiteLink});
        //   }else {
        //     playersList.push({websiteLink: item?.websiteLink});
        //   }
        // }
        if (item?.website?.trim() === website) {
          if (!streamSource) {streamSource = item?.websiteLink};
          playersList.push({websiteLink: item?.websiteLink});
        }
        // if (item?.website?.trim() === 'Sportsfeed24 Streams') {
        //   streamSource = item?.websiteLink;
        //   playersList.unshift({websiteLink: item?.websiteLink});
        // }
      }
      if (fixture?.game?.websiteLink) {
        if(playersList.length){
          if (fixture?.game?.websiteLink != playersList[0].websiteLink){
            streamSource = fixture?.game?.websiteLink;
            playersList.unshift({websiteLink: fixture?.game?.websiteLink});
          }
        }else {
          streamSource = fixture?.game?.websiteLink;
          playersList.unshift({websiteLink: fixture?.game?.websiteLink});
        }
      }
      setSourceLink(streamSource);
      setPlayers(playersList);

      setLoader(false);
      // Return a default value if the ID is not found
      // let teamA: string | undefined;
      // let teamB: string | undefined;
      // console.log('PROPS:::', props);
  
      // const response: any = await getIframe({ teamA: teamA, teamB: teamB});
      // setIframe(response.data);
    }

    const handlePlayerChange = (player: any) => {
      setSourceLink(player?.websiteLink);
    };
  
    useEffect(() => {
      window.scrollTo(0, 0);
      getFixtureFrame();
    }, []);
  
    return (
      <>
        <Row style={styles.flexDisplay}>
          {/* <AdSenseBanner slot={ADS.MAIN.SLOT} type={ADS.MAIN.TYPE}></AdSenseBanner> */}
        </Row>
        <Space style={styles.shareStream}>
          <p style={styles.shareText}>Share Stream:</p>
          <Link to={shareLink('facebook', fixture)} target='_blank'><FacebookFilled style={{ color: '#1877F2' }} /></Link>
          <Link to={shareLink('twitter', fixture)} target='_blank'><TwitterSquareFilled style={{ color: '#000' }}/></Link>
          <Link to={shareLink('linkedin', fixture)} target='_blank'><LinkedinFilled style={{color: '#0077B5'}}/></Link>
          <Link to={shareLink('reddit', fixture)} target='_blank'><RedditOutlined style={{color: '#FF4500'}}/></Link>
          <Link to={shareLink('skype', fixture)} target='_blank'><SkypeFilled  style={{color: '#00AFF0'}}/></Link>
          <Link to={shareLink('whatsapp', fixture)} target='_blank'><MessageFilled  style={{color: '#25D366'}}/></Link>
        </Space>
        {(matches || players.length < 5) && <div style={{marginBottom: '5px'}}>
          {players?.map((player:any, index:number) => {
            return <Button 
            key={index} 
            className='time-button' 
            style={{ backgroundColor: '#2298ff', marginRight: '5px' }} 
            shape="round"
            onClick={() => handlePlayerChange(player)}
            disabled={player?.websiteLink === sourceLink}
            >
              Player {index + 1} 
            </Button>
          })}
        </div>}
        {!matches && players?.length > 4 && <PlayerDropdown 
        players={players} 
        sourceLink={sourceLink} 
        handlePlayerChange={handlePlayerChange} 
      />}
      {
      sourceLink &&
        <Row style={playerBorder}>
          <Col span={24}>
          {!sourceLink && <div style={styles.loadingPlaceholder}>Loading...</div>}
          <iframe frameBorder={0} marginHeight={0} marginWidth={0} height={matches ? '100%':'250'} width={matches ? '100%':'350'} src={sourceLink} allowFullScreen={true} scrolling='no'></iframe>
          {loader ? <Loader /> : <></>}
          </Col>
        </Row>
      }
      </>
    );
  }
  