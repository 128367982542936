// import { useEffect } from 'react';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import { getBannerSrc } from '../../utils/bannerSource';
import { BCAFFILIATELINK } from '../../utils/constant';
import './index.css'

declare global {
  interface Window {
    adsbygoogle: unknown[]
  }
}

interface AdSenseProps {
  type: string;
}

function BCBanner({ type }: AdSenseProps) {
    const matches = useMediaQuery('(min-width: 1000px)');

    const bannerSrc = getBannerSrc(type, matches); 

  return (
    <div key={type} style={{marginTop: '5px', marginBottom: '10px' }}>
        <a className='affiliate-link' href={BCAFFILIATELINK} target="_blank" rel="noreferrer">
              <img className='everygame-image' src={bannerSrc} alt="BC Game Affiliate" />
        </a>
    </div>
  )
}

export default BCBanner