import { AFFILIATE_BANNERS } from './constant';

export function getBannerSrc(type: string, matches: boolean): any {
    if(matches && type === 'SIDE'){
        const max = AFFILIATE_BANNERS.HOME_WEB.SIDE.length - 1;
        const index = Math.floor(Math.random() * (max - 0 + 1)) + 0;
        return AFFILIATE_BANNERS.HOME_WEB.SIDE[index];
    }
    if(matches && type === 'TOP'){
        const max = AFFILIATE_BANNERS.HOME_WEB.TOP.length - 1;
        const index = Math.floor(Math.random() * (max - 0 + 1)) + 0;
        return AFFILIATE_BANNERS.HOME_WEB.TOP[index];
    }
    if(!matches && type === 'TOP'){
        const max = AFFILIATE_BANNERS.HOME_MOBILE.TOP.length - 1;
        const index = Math.floor(Math.random() * (max - 0 + 1)) + 0;
        return AFFILIATE_BANNERS.HOME_MOBILE.TOP[index];
    }
}